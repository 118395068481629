/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
 *,
 *::before,
 *::after {
   box-sizing: border-box;
 }
 
 html {
   font-family: sans-serif;
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }
 
 article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
   display: block;
 }
 
 body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   text-align: left;
   background-color: #fff;
 }
 
 [tabindex="-1"]:focus {
   outline: 0 !important;
 }
 
 hr {
   box-sizing: content-box;
   height: 0;
   overflow: visible;
 }
 
 h1, h2, h3, h4, h5, h6 {
   margin-top: 0;
   margin-bottom: 0.5rem;
 }
 
 p {
   margin-top: 0;
   margin-bottom: 1rem;
 }
 
 abbr[title],
 abbr[data-original-title] {
   text-decoration: underline;
   -webkit-text-decoration: underline dotted;
   text-decoration: underline dotted;
   cursor: help;
   border-bottom: 0;
   -webkit-text-decoration-skip-ink: none;
   text-decoration-skip-ink: none;
 }
 
 address {
   margin-bottom: 1rem;
   font-style: normal;
   line-height: inherit;
 }
 
 ol,
 ul,
 dl {
   margin-top: 0;
   margin-bottom: 1rem;
 }
 
 ol ol,
 ul ul,
 ol ul,
 ul ol {
   margin-bottom: 0;
 }
 
 dt {
   font-weight: 700;
 }
 
 dd {
   margin-bottom: .5rem;
   margin-left: 0;
 }
 
 blockquote {
   margin: 0 0 1rem;
 }
 
 b,
 strong {
   font-weight: bolder;
 }
 
 small {
   font-size: 80%;
 }
 
 sub,
 sup {
   position: relative;
   font-size: 75%;
   line-height: 0;
   vertical-align: baseline;
 }
 
 sub {
   bottom: -.25em;
 }
 
 sup {
   top: -.5em;
 }
 
 a {
   color: #007bff;
   text-decoration: none;
   background-color: transparent;
 }
 
 a:hover {
   color: #0056b3;
   text-decoration: underline;
 }
 
 a:not([href]):not([tabindex]) {
   color: inherit;
   text-decoration: none;
 }
 
 a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
   color: inherit;
   text-decoration: none;
 }
 
 a:not([href]):not([tabindex]):focus {
   outline: 0;
 }
 
 pre,
 code,
 kbd,
 samp {
   font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
   font-size: 1em;
 }
 
 pre {
   margin-top: 0;
   margin-bottom: 1rem;
   overflow: auto;
 }
 
 figure {
   margin: 0 0 1rem;
 }
 
 img {
   vertical-align: middle;
   border-style: none;
 }
 
 svg {
   overflow: hidden;
   vertical-align: middle;
 }
 
 table {
   border-collapse: collapse;
 }
 
 caption {
   padding-top: 0.75rem;
   padding-bottom: 0.75rem;
   color: #6c757d;
   text-align: left;
   caption-side: bottom;
 }
 
 th {
   text-align: inherit;
 }
 
 label {
   display: inline-block;
   margin-bottom: 0.5rem;
 }
 
 button {
   border-radius: 0;
 }
 
 button:focus {
   outline: 1px dotted;
   outline: 5px auto -webkit-focus-ring-color;
 }
 
 input,
 button,
 select,
 optgroup,
 textarea {
   margin: 0;
   font-family: inherit;
   font-size: inherit;
   line-height: inherit;
 }
 
 button,
 input {
   overflow: visible;
 }
 
 button,
 select {
   text-transform: none;
 }
 
 select {
   word-wrap: normal;
 }
 
 button,
 [type="button"],
 [type="reset"],
 [type="submit"] {
   -webkit-appearance: button;
 }
 
 button:not(:disabled),
 [type="button"]:not(:disabled),
 [type="reset"]:not(:disabled),
 [type="submit"]:not(:disabled) {
   cursor: pointer;
 }
 
 button::-moz-focus-inner,
 [type="button"]::-moz-focus-inner,
 [type="reset"]::-moz-focus-inner,
 [type="submit"]::-moz-focus-inner {
   padding: 0;
   border-style: none;
 }
 
 input[type="radio"],
 input[type="checkbox"] {
   box-sizing: border-box;
   padding: 0;
 }
 
 input[type="date"],
 input[type="time"],
 input[type="datetime-local"],
 input[type="month"] {
   -webkit-appearance: listbox;
 }
 
 textarea {
   overflow: auto;
   resize: vertical;
 }
 
 fieldset {
   min-width: 0;
   padding: 0;
   margin: 0;
   border: 0;
 }
 
 legend {
   display: block;
   width: 100%;
   max-width: 100%;
   padding: 0;
   margin-bottom: .5rem;
   font-size: 1.5rem;
   line-height: inherit;
   color: inherit;
   white-space: normal;
 }
 
 progress {
   vertical-align: baseline;
 }
 
 [type="number"]::-webkit-inner-spin-button,
 [type="number"]::-webkit-outer-spin-button {
   height: auto;
 }
 
 [type="search"] {
   outline-offset: -2px;
   -webkit-appearance: none;
 }
 
 [type="search"]::-webkit-search-decoration {
   -webkit-appearance: none;
 }
 
 ::-webkit-file-upload-button {
   font: inherit;
   -webkit-appearance: button;
 }
 
 output {
   display: inline-block;
 }
 
 summary {
   display: list-item;
   cursor: pointer;
 }
 
 template {
   display: none;
 }
 
 [hidden] {
   display: none !important;
 }
 
* {
  margin: 0;
  padding: 0;
}

button:focus {
  outline: none;
}

a {
  color: #ffffff;
  font-weight: bold;
}

a:hover {
  color: #ffffff;
}

html, body {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
}

body {
  letter-spacing: 1px;
}

.symphony, 
#symphony-root,
.loading-container {
  height: 100%
}

[tooltip] {
  position: relative;
  display: inline-block;
}

[tooltip]::before {
  content: "";
  position: absolute;
  left: 50%;
  z-index: 99;
  opacity: 0;
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
  transition: opacity .2s
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  text-align: center;
  color: #fff;
  padding: 4px 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: normal!important;
  min-width: 150px;
  pointer-events: none;
  padding: 4px 4px;
  z-index: 99;
  opacity: 0;
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
  transition: opacity .2s;
}

[tooltip]:hover::after, 
[tooltip]:hover::before {
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  [tooltip]::before, [tooltip]::after {
    display: none;
  }
}

canvas#symphony-stage {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  display: block;
}

canvas#sketchboard {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #000000;
  display: none;
}

.symphony-ui {
  font-size: 12px;
  position: fixed;
  top: 0;
  left: 0;
  color: #cecece;
  font-family: 'Inconsolata', monospace;
  text-transform: uppercase;
  font-weight: 300;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .symphony-ui {
    font-size: 12px;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    color: #cecece;
    font-family: 'Inconsolata', monospace;
    text-transform: uppercase;
    font-weight: 300;
    width: 100%;
    height: auto;
  }
}

.symphony-ui h2 {
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
  color: #cecece;
  width: 100%;
  padding: 0 0 7px;
  margin: 0 0 7px;
}


.symphony-ui h3 {
  font-weight: normal;
  font-size: 11px;
  text-transform: uppercase;
  color: #cecece;
  display: inline-block;
  margin: 0;
}

.symphony-ui ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.symphony-ui li {
  color: #cecece;
  line-height: 20px;
}

.symphony-ui li a {
  color: #cecece;
  position: relative;
  z-index: 2; 
}

.health-bar-container {
  width: 100px;
  border: 1px solid rgba(255,255,255,0.2);
  background: rgba(255,255,255,0.1);
  height: 6px;
  display: inline-block;
  margin: 0 0 0 3px;
}

.health-bar {
  position: absolute;
  background: #009800;
  height: 4px;
}

.block-hash {
  position: absolute;
  bottom: 43px;
  left: 122px;
}

.block-details-border,
.info-panel-border {
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  height: 100%;
  width: 42px;
  position: absolute;
  left: -1px;
  top: -1px;
  opacity: 0.3;
}

@media only screen and (max-width: 600px) {
  .block-details-border,
  .info-panel-border {
    display:none;
  }
}

.block-details,
.info-panel {
  pointer-events: auto;
  position: absolute;
  right: 20px;
  top: 106px;
  width: 222px;
  z-index: 9;
  background: rgba(0, 0, 0, 0.25);
  padding: 10px 12px 10px;
  -webkit-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 1.0;
}

.info-panel {
  top: 390px;
}

@media only screen and (max-width: 600px) {
  .block-details {
    background: none;
    width: 100%;
    padding: 0;
    z-index: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    top: auto;
    background: rgba(0, 0, 0, 0.4);
    height: auto;
    z-index: 2;
  }

  .block-details.tx-selected {
    display: none;
  }

  .info-panel {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .block-details-inner {
    position: static;
    padding: 20px;
    background: none;
  }
}


.block-details-heading,
.info-panel-heading {
  position: absolute;
  left: 0;
  top: -29px;
}

@media only screen and (max-width: 600px) {
  .block-details-heading {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    top: auto;
    bottom: 80vh;
    font-size: 5vw!important;
  }
}

@media only screen and (max-width: 400px) {
  .block-details-heading {
    bottom: 70vh;
  }
}

.block-details .view-details {
  padding-top: 10px;
}

@media only screen and (max-width: 600px) {
  .block-details .view-details {
    padding-top: 0;
    position: relative;
    z-index: 2;
  }
}

.block-details strong {
  font-weight: bold;
}

.block-details .line {
  background: #153453;
  width: 180px;
  height: 1px;
  position: absolute;
  display: block;
  left: -180px;
  top: 19px;
}

.block-details .dot {
  background: #709cf0;
  border-radius: 10px;
  display: block;
  width: 5px;
  height: 5px;
  position: absolute;
  left: -3px;
  top: 17px;
}

.controls-container {
  left: 50%;
  margin-left: -51px;
  top: 20px;
  width: 150px;
  position: absolute;
  z-index: 9;
}


.cockpit-border {
  background: url(./assets/images/vignette2.png) no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.13;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .cockpit-border {
    display: none;
  }
}

.block-details ul {
  display: inline-block;
}


@media only screen and (max-width: 600px) {
  .block-details ul {
    -moz-column-count: 2;
    -moz-column-gap: 0;
    -webkit-column-count: 2;
    -webkit-column-gap: 0;
    column-count: 2;
    column-gap: 0;
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }
}

.explore-container {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 20px;
  display: block;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .explore-container {
    display: none;
  }
}

.cancel {
  display: block;
  width: 45px;
  height: 38px;
  background: url(./assets/images/cancel.svg) no-repeat;
  border: none;
  opacity: 1.0;
  position: absolute;
  top: 0;
  left: 5px;
}

.cancel:hover {
  cursor: pointer;
}

.toggle-cockpit-controls {
  background: url(./assets/images/cockpit.png) no-repeat;
  background-size: contain;
  border: none;
  width: 58px;
  height: 41px;
  opacity: 0.9;
  transition: opacity 0.5s;
  display: block;
  position: absolute;
}

.toggle-cockpit-controls.leave {
  position: absolute;
  width: 32px;
  height: 41px;
  opacity: 0.9;
  left: 8px;
  top: 9px;
}

.toggle-cockpit-controls:hover {
  opacity: 1.0;
}

.toggle-cockpit-controls:hover {
  cursor: pointer;
}

.flip-view-container {
  padding: 11px;
  display: block;
  position: absolute;
  left: 45%;
  top: 20px;
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  .flip-view-container {
    top: auto;
    bottom: 90vh;
  } 
}

@media only screen and (max-width: 400px) {
  .flip-view-container {
    bottom: 80vh;
  } 
}

.flip-view {
  display: block;
  width: 18px;
  height: 18px;
  background: url(./assets/images/flip-view.svg) no-repeat;
  border: none;
  opacity: 0.8;
}

.flip-view:hover {
  cursor: pointer;
  opacity: 1.0;
}


.tx-details {
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  left: 50%;
  border: 0;
  padding: 15px;
  width: 600px;
  margin-left: -300px;
  text-align: center;
  bottom: 50px;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  .tx-details {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    border: 0;
    padding: 15px;
    width: 100%;
    margin-left: 0;
    text-align: center;
    bottom: 0;
    z-index: 2;
  }
}

.tx-details ul {
  text-align: left;
  width: 50%;
  bottom: -30px;
  display: inline-block;
}

.in-total {
}

.out-total {
}

.tx-details .input-output {
  margin-top: 10px;
  width: 100%!important;
  background: url(./assets/images/arrow.png) no-repeat center center;
}

.input-output li.inputs {
  width: 55%;
}

.input-output li.outputs {
  width: 45%;
}

.input-output h3 {
  display: block;
}

.input-output li {
  vertical-align: top;
  width: 50%;
  display: inline-block;
}

.input-output li li {
  width: 100%;
  display: inline-block;
}

.input-output ul {
  width: 100%
}

.tx-detail-item {
  display: inline-block;
  margin-right: 20px;
}

.tx-details h2 {
  border: none;
  color: #fff;
  text-align: center;
  margin-top: 0;
  padding: 0;
  font-size: 13px;
}

.tx-details strong {
  font-weight: normal;
}

.tx-details-inner {
  display: block;
}

.crosshair {
  display: block;
  background: url(./assets/images/crosshair.svg) no-repeat;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  opacity: 0.8;
}

.sidebar-container {
  position: absolute;
  z-index: 2;
}

.sidebar {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid rgba(255,255,255, 0.1);
  z-index: 10;
  padding: 17px;
  transition: width 0.5s;
  transition-timing-function: ease-in-out;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    background: rgba(0, 0, 0, 1.0);
  }
}

.sidebar.closed {
  width: 58px;
}

@media only screen and (max-width: 600px) {
  .sidebar.closed {
    background: none;
    border: none;
    height: 20px;
  }

  .sidebar.closed .key {
    display: none;
  }

  .sidebar.closed .explore {
    display: none;
  }

  .sidebar.closed .sidebar-footer {
    display: none;
  }
}

.sidebar.open {
  width: 230px;
}

.search {
  background: url(./assets/images/search.svg) no-repeat;
  width: 20px;
  height: 20px;
  border: none;
  margin: 0 10px 0 0;
  float: left;
  position: relative;
  z-index: 1;
}

.search:hover {
  cursor: pointer;
}

.calendar {
  background: url(./assets/images/calendar.svg) no-repeat;
  width: 20px;
  height: 20px;
  border: none;
  margin: 0 10px 0 0;
  float: left;
  position: relative;
  z-index: 1;
}

.calendar:hover {
  cursor: pointer;
}

.sidebar.camera-animating {
  visibility: hidden;
}

.sidebar .section {
  padding: 10px 0;
  margin: 0 0 15px;
  border-bottom: 1px solid rgba(255, 255,255, 0.3)
}

.sidebar ul span {
  transition: opacity 0.5s;
  transition-timing-function: ease-in-out;
}

.sidebar ul span:hover {
  cursor: pointer;
}

.sidebar li {
  width: 240px;
  padding: 0;
  overflow: hidden;
  font-size: 13px;
}

.sidebar li span {
  display: block;
  margin: 0 0 10px 32px;
}

.sidebar.closed ul span {
  opacity: 0.0;
  visibility: hidden
}

.sidebar.open ul span {
  opacity: 1.0;
}

.sidebar.open ul span:hover {
  color: #fff
}

.sidebar ul span {
  vertical-align: top;
}

.sidebar h1 {
  font-family: 'Montserrat';
  overflow: hidden;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: normal;
  transition: opacity 0.5s;
  transition-timing-function: ease-in-out;
}

.sidebar h2 {
  overflow: hidden;
  display: inline-block;
  width: 240px;
  text-transform: none;
  color: #d8d6d7;
  font-weight: bold;
  letter-spacing: 1px;
  border: none;
  transition: opacity 0.5s;
  transition-timing-function: ease-in-out;
  padding: 0 0 20px;
  text-transform: uppercase;
}

.sidebar h3 {
  overflow: hidden;
  font-weight: bold;
  color: #cecece;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  transition: opacity 0.5s;
  transition-timing-function: ease-in-out;
  padding: 0 0 20px;
}

.sidebar.closed h1,
.sidebar.closed h2,
.sidebar.closed h3 {
  opacity: 0.0;
}

.sidebar.open h1,
.sidebar.open h2,
.sidebar.open h3 {
  opacity: 1.0;
}

.sidebar .expand {
  position: relative;
  z-index: 2;
  border: none;
  float: right;
  width: 20px;
  height: 20px;
  background: url(./assets/images/expand.svg) no-repeat;
}

.sidebar .expand:hover {
  cursor: pointer;
}

.sidebar.open .expand {
  background: url(./assets/images/contract.svg) no-repeat;
}

.search-container {
  background:rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 50%;
  left: 50%;
  border: none;
  z-index: 10;
  padding: 17px;
  width: 522px;
  height: 100px;
  margin-top: -50px;
  margin-left: -261px;
}

@media only screen and (max-width: 600px) {
  .search-container {
    top: -50vh;
    width: 100vw;
    margin: 0;
    left: 0;
  }
}

.search-container h2 {
  border: none;
}

.search-box,
.react-datepicker-wrapper input {
  width: 453px;
  height: 28px;
  float: left;
}

@media only screen and (max-width: 600px) {
  .search-box,
  .react-datepicker-wrapper input {
    width: 90%;
  }
}

.search-box-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-weight: bold;
  color: #cecece;
}

.search-box-close:hover {
  cursor: pointer;
  color: #ffffff;
}

.search-action {
  background: url(./assets/images/search.svg) no-repeat;
  width: 20px;
  height: 20px;
  border: none;
  margin: 2px 0px 0 0;
  float: right;
}

.search-action:hover {
  cursor: pointer;
}


.blockchain-selector {
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  bottom: 117px;
  left: 10%;
  background: rgba(18,23,38,0.9);
  padding: 4px;
  border-radius: 50px;
  z-index: 9;
  border: 1px solid #153453;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
}

.blockchain-selector span {
  padding: 0 8px;
}

.blockchain-selector .down-arrow {
  padding: 0 8px;
}

.iohk-logo {
  position: absolute;
  bottom: 0;
  right: 16px;
}

.iohk-supported {
  opacity: 1.0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 16px;
  width: 146px;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.sidebar-footer {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  height: 41px;
}

.sidebar-footer-inner {
  border-top: 1px solid rgba(255,255,255, 0.3);
  margin: 0 15px;
}

.sidebar.closed .iohk-supported {
  opacity: 0.0;
}

.sidebar.open .iohk-supported {
  opacity: 0.5;
  letter-spacing: 0
}

.key h3 {
  padding-bottom: 10px;
  width: 277px;
}

.key .sidebar-hide {
  width: 277px;
  padding-bottom: 25px;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.sidebar.closed .key .sidebar-hide {
  opacity: 0.0;
  visibility: hidden;
}

.sidebar.open .key .sidebar-hide {
  opacity: 1.0;
}

.sidebar .key .sidebar-show {
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  position: absolute;
}

.sidebar.closed .key .sidebar-show {
  opacity: 1.0;
}

.sidebar.open .key .sidebar-show {
  opacity: 0.0;
}

.spending-key {
  margin: 0 20px 0 0;
}

.spending-key span {
  display: inline-block;
  margin: 0 0 0 5px;
}

.hud {
  pointer-events: auto;
  background: url(./assets/images/hud.png) no-repeat center center;
  position: fixed;
  z-index: 2;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
}

.coords {
  position: absolute;
  bottom: 43px;
  right: 127px;
  width: 103px;
}

.block-hash h2 {
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .block-hash h2 {
    display: none;
  }
}

.block-hash h3 {
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .block-hash h3 {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .block-hash {
    bottom: 15px;
    left: 15px;
  }
}

.tx-details-border {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0.3;
}

.tx-details-border-tl {
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  top: -1px;
  left: -1px;
}

.tx-details-border-tr {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  top: -1px;
  right: -1px;
}

.tx-details-border-bl {
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  bottom: -1px;
  left: -1px;
}

.tx-details-border-br {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  bottom: -1px;
  right: -1px;
}

@media only screen and (max-width: 600px) {
  .tx-details-border-tl, 
  .tx-details-border-tr, 
  .tx-details-border-bl, 
  .tx-details-border-br {
    display:none;
  }

  .tx-details h2 {
    display: none;
  }
}

.loading-container {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  width: 100%; 
  height: 100%; 
  background: #000;
  background-size: cover;
  position: relative;
  z-index: 1;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: opacity 10s, filter 10s;
}

.landing-bg {
  width: 100%; 
  height: 100%; 
  background: #000 url(./assets/images/landing.jpg) no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 0;
  color: #fff;
  justify-content: center;
  opacity: 0.10;
  filter: grayscale(100%);
}

@media only screen and (max-width: 600px) {
  .landing-bg {
    background-image: none;
  }
}

.loading-container h1 {
  font-weight: 300;
  font-size: 1vw;
  text-transform: uppercase;
  animation: blur-in 2.5s ease-in;
  letter-spacing: 10px;
}

@media only screen and (max-width: 600px) {
  .loading-container h1 {
    font-size: 7vw;
  }
}


.loading-container h2 {
  font-weight: 300;
  font-size: 0.7vw;
  text-transform: uppercase;
  animation: blur-in 2.5s ease-in;
  letter-spacing: 1px;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .loading-container h2 {
    font-size: 14px;
  }
}

.loading-container h1 span {
  font-weight: 500;
}

.symphony-logo {
  width: 10vw;
  opacity: 0.8;
  animation: blur-in 2.5s ease-in;
  mix-blend-mode: screen;
}

.symphony-logo:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .symphony-logo {
    width: 40vw;
  }
}

.symphony-logo.pulsate {
  animation: pulsate 2.0s ease-in-out infinite both, blur-in 2.5s ease-in;
}

.logo-container p {
  text-transform: uppercase;
  opacity: 0.6
}

.logo-container .fa-headphones-alt {
  opacity: 0.6;
  color: #ddd;
  animation: blur-in 2.5s ease-in;
}

.loaded {
  display: none;
  opacity: 0.0;
  filter: blur(2ex);
  /* transition: opacity 10s, filter 10s, visibility 10s; */
  visibility: hidden;
  /* animation: zoom-in 10s ease-in; */
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacity-pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.45;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes blur-in {
  0% {
    filter: blur(2ex);
  }
  100% {
    filter: blur(0ex);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(3.0);
  }
}

@keyframes flicker {
  0% {
    opacity: 1.0;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 1.0;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 1.0;
  }
}


.intro-container {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  color: #fff;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
  padding-bottom: 6%;
}

.intro-container h1 {
  font-weight: 400;
  font-size: 1vw;
  text-transform: uppercase;
  position: absolute;
  opacity: 0;
  filter: blur(2ex);
  transition: opacity 2s, filter 2s;
}

@media only screen and (max-width: 600px) {
  .intro-container h1 {
    font-size: 4.8vw;
    padding: 20px;
    line-height: 33px;
  }
}

.intro-container h1.show {
  opacity: 1;
  filter: blur(0ex);
  transition: opacity 3s, filter 3s;
  /* animation: opacity-pulse 4.0s ease-in-out infinite both; */
}

.intro-container h1 a {
  font-weight: 500
}

.enter-blockchain-text:hover {
  cursor: pointer;
}

.start-container {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  color: #fff;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
  padding-bottom: 6%;
}

.start-container h1 {
  font-weight: 400;
  font-size: 1vw;
  text-transform: uppercase;
  position: absolute;
}

.start-container h1:hover {
  cursor: pointer;
}

.not-supported {
  display: none;
}

.block-navigation-prev {
  transition: opacity .5s;
  z-index: 9;
  position: absolute;
  top: 50%;
  left: -12vh;
  transform: translate(0, -50%);
  display: inline-block;
  width: 9vh;
  height: 100px;
  text-indent: -9999px;
  background: url(./assets/images/prev-block.svg) no-repeat;
  border: none;
  opacity: 0.6;
}

@media only screen and (max-width: 600px) {
  .block-navigation-prev {
    left: -9vh;
    width: 5vh;
    top: auto;
    bottom: 50vh;
  }
}

.block-navigation-prev:hover {
  cursor: pointer;
  opacity: 1.0;
}

.block-navigation-prev.hide {
  display: none;
}

.block-navigation-next {
  transition: opacity .5s;
  z-index: 9;
  position: absolute;
  top: 50%;
  right: -12vh;
  transform: translate(0, -50%);
  display: inline-block;
  width: 9vh;
  height: 100px;
  text-indent: -9999px;
  background: url(./assets/images/next-block.svg) no-repeat;
  background-position: right;
  border: none;
  opacity: 0.6;
}

@media only screen and (max-width: 600px) {
  .block-navigation-next {
    right: -9vh;
    width: 5vh;
    top: auto;
    bottom: 50vh;
  }

}

.block-navigation-next:hover {
  cursor: pointer;
  opacity: 1.0;
}

.block-navigation-next.hide {
  display: none;
}

.block-navigation {
  position: absolute;
  width: 75.7vh;
  height: 75.7vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
  .block-navigation {
    width: 58.8vw;
    height: 58.8vw;
    z-index: 1;
  }
}

.grad-left {
  height: 100%;
  width: 100%;
  position: fixed;
  background: linear-gradient(to right, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 40%);
}
.grad-right {
  height: 100%;
  width: 100%;
  position: fixed;
  background: linear-gradient(to left, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 40%);
}

@media only screen and (max-width: 600px) {
  .grad-left {
    display: none;
  }
  .grad-right {
    display: none;
  }
}

.autopilot-controls {
  position: absolute;
  left: 55%;
  top: 20px;
  width: 129px;
  z-index: 9;
  padding: 0 12px;
  -webkit-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 1.0;
}

@media only screen and (max-width: 600px) {
  .autopilot-controls {
    display: none;
  }
}

.autopilot-inner {
  text-align: center;
}

.autopilot-controls-border {
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  height: 270px;
  width: 42px;
  position: absolute;
  left: -1px;
  top: -1px;
  opacity: 0.3;
  height: 45px;
}

.autopilot-controls-heading {
  position: absolute;
  left: 0;
  top: -29px;
}

.autopilot-controls span {
  display: inline-block;
  width: 10px;
  height: 19px;
  margin: 10px 10px;
  opacity: 0.7;
  transition: opacity 0.5s;
}

.autopilot-controls span:hover {
  cursor: pointer;
  opacity: 1.0;
}

.autopilot-controls span.backward {
  background: url(./assets/images/contract.svg) no-repeat;
}

.autopilot-controls span.forward {
  background: url(./assets/images/expand.svg) no-repeat;
}

.autopilot-controls span.stop {
  width: 17px;
  height: 19px;
  border: 1px solid #ffffff;
}

.intro-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  animation: blur-in 2.5s ease-in, fade-in 2.5s ease-in;
}

.intro-overlay.hide {
  opacity: 0;
  transition: opacity 3s, filter 3s;
  filter: blur(2ex);
}

@media only screen and (max-width: 600px) {
  .intro-overlay {
    display:none;
  }
}

.intro-overlay p {
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  z-index: 1;
  text-align: center;
}

.intro-overlay-merkle {
  left: 45%;
  top: 29px;
  margin-left: -170px;
}

.intro-overlay-free-explore {
  left: 50%;
  top: 65px;
  margin-left: -54px;
}

.intro-overlay-block-details {
  right: 246px;
  top: 242px;
  width: 13vw;
}

.intro-overlay-autopilot {
  left: 55%;
  top: 29px;
  margin-left: 117px;
}

.intro-overlay-sidebar {
  top: 30%;
  left: 75px;
}

.intro-overlay-transactions {
  position: absolute;
  width: 75.7vh;
  height: 75.7vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px;
}

.intro-overlay-transactions p {
  text-align: center;
  position: static;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: normal;
  text-transform: none;
}

.intro-overlay-start-explore {
  position: absolute;
  font-family: 'Inconsolata', monospace;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 12px 30px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  color: #cecece;
  font-size: 17px;
}

.action-button {
  overflow: hidden;
}

.action-button p {
  position: static;
  padding: 0;
  margin: 0;
}

.action-button:hover {
  cursor: pointer;
}
.action-button:hover p {
  cursor: pointer;
  animation: flicker 0.25s;
}

.action-button:hover .tl {
  top: 3px;
  left: 3px;
  transition: top 0.1s, left 0.1s;
}
.action-button:hover .tr {
  top: 3px;
  right: 3px;
  transition: top 0.1s, right 0.1s;
}
.action-button:hover .bl {
  bottom: 3px;
  left: 3px;
  transition: bottom 0.1s, left 0.1s;
}
.action-button:hover .br {
  bottom: 3px;
  right: 3px;
  transition: bottom 0.1s, right 0.1s;
}

.action-button .swipe {
  background: #fff;
  width: 100%;
  height: 100%;
  left: -100%;
  top: 0;
  position: absolute;
  opacity: 0.2;
  transition: left 0.3s;
  clip-path: polygon(90% 0, 100% 0, 80% 100%, 70% 100%);
  z-index: 2;
}

.action-button:hover .swipe {
  left: 100px;
}

.action-button span {
  position: absolute;
  width: 10px;
  height: 10px;
  display: block;
  opacity: 0.3;
}

.action-button .tl {
  left: 0;
  top: 0;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
}
.action-button .tr {
  right: 0;
  top: 0;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
}
.action-button .bl {
  left: 0;
  bottom: 0;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.action-button .br {
  right: 0;
  bottom: 0;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.free-explore-info-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  animation: blur-in 2.5s ease-in, fade-in 2.5s ease-in;
  z-index: 3;
}

.free-explore-info-overlay > p {
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 200px;
  width: 600px;
  text-align: center;
  
}

.free-explore-keys {
  position: absolute;
  width: 320px;
  margin-left: -160px;
  top: 365px;
  left: 50%;
}

.free-explore-key {
  font-size: 40px;
  padding: 10px 20px;
  margin: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.free-explore-key-container {
  display: inline-block;

}

.free-explore-key-container > p {
  position: absolute;
  left: 0;
  top: 0;
}

.free-explore-key-container .key-q {
  left: -99px;
  top: 44px;
}

.free-explore-key-container .key-w {
  left: 97px;
  top: -15px;
}

.free-explore-key-container .key-e {
  left: 305px;
  top: 43px;
  width: 152px;
}

.free-explore-key-container .key-a {
  left: -79px;
  top: 169px;
}

.free-explore-key-container .key-s {
  left: 93px;
  top: 237px;
}

.free-explore-key-container .key-d {
  left: 305px;
  top: 169px;
  width: 200px;
}

.go-button {
  position: absolute;
  font-family: 'Inconsolata', monospace;
  text-transform: uppercase;
  top: 697px;
  left: 50%;
  width: 140px;
  z-index: 1;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  color: #cecece;
  font-size: 15px;
  margin-left: -70px;
}

.go-button:hover {
  cursor: pointer;
}

.choose-quality {
  font-size: 12px;
  padding: 15px 0 0;
  opacity: 0.8;
  text-transform: uppercase;
  animation: blur-in 2.5s ease-in;
}

@media only screen and (max-width: 600px) {
  .choose-quality {
    padding: 50px 0 0;
  }
}

.quality-select {
  opacity: 0.6;
  padding: 0 20px;
  animation: blur-in 2.5s ease-in;
}

.quality-select:hover {
  cursor: pointer;
  opacity: 1;
}

.sound-hint {
  font-size: 11px;
  margin: 50px 0 0;
}

@media only screen and (max-width: 600px) {
  .sound-hint {
    display: none;
  }
}

.volume-controls {
  position: absolute;
  z-index: 2;
  font-size: 15px;
  bottom: -91px;
  opacity: 0.7;
}

.volume-controls i:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .volume-controls {
    display: none;
  }
}

.rotate-message {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #000;
  display: none;
}

.phone {
  height: 50px;
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  opacity: 0.6;
}

.message {
  color: white;
  font-size: 1em;
  margin-top: 40px;
  opacity: 0.6;
}

@keyframes rotate {
  0% {
		transform: rotate(0deg)
	}
	50% {
		transform: rotate(-90deg)
	}
	100% {
		transform: rotate(-90deg)
	}
}

@media only screen and (max-width: 824px) { 
  .landscape .rotate-message {
    display: flex;
  }
}

.react-datepicker {
  background: #000;
  color: #cecece;
  border: none;
  opacity: 0.8;
  border-radius: 0;
  font-family: 'Inconsolata', monospace;
}

.react-datepicker__header {
  color: #cecece;
  background: #000;
}

.react-datepicker__current-month, 
.react-datepicker-time__header, 
.react-datepicker-year-header {
  color: #cecece;
}

.react-datepicker__day-name, 
.react-datepicker__day, 
.react-datepicker__time-name {
  color: #cecece;
}

.react-datepicker__day:hover, 
.react-datepicker__month-text:hover {
  border-radius: 0;
  color: #000;
}

.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range {
  border-radius: 0;
  background: #223577
}

.react-datepicker__day--disabled, 
.react-datepicker__month-text--disabled {
  color: #333;
}

.fa-btc:before {
  margin: 0 4px 0 0;
}

.headphone-hint {
  color: #ddd;
  font-size: 10px;
  margin-top: 25px;
  margin-bottom: 5px;
  text-transform: uppercase;
  animation: blur-in 2.5s ease-in;
}

@media only screen and (max-width: 600px) {
  .headphone-hint,
  .logo-container .fa-headphones-alt {
    display: none;
  }
}

.video-bg {
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  opacity: 0.2;
}

@media only screen and (max-width: 600px) {
  .video-bg {
    display: none;
  }
}

.virtual-exhibition {
  position: relative;
  z-index: 1;
}

.virtual-exhibition img{
  width: 100%;
  margin-top: 8vh;
}
